import React, { useContext, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { AppContext } from './Context';
import WelcomePage from './welcomePage';
import CreateMatrimony from './createMatrimony';
import StepForm from './stepComponents/stepForm';
import Preview from './stepComponents/preview';
import FoundMatches from './foundMatches';

// Define constants for page numbers
const WELCOME_PAGE = 0;
const CREATE_MATRIMONY = 1;
const STEP_FORM = 2;
const PREVIEW = 3;
const FOUND_MATCHES = 4;

const handlePages = (page: number) => {
  switch (page) {
    case WELCOME_PAGE:
      return <WelcomePage />;
    case CREATE_MATRIMONY:
      return <CreateMatrimony />;
    case STEP_FORM:
      return <StepForm />;
    case PREVIEW:
      return <Preview />;
    case FOUND_MATCHES:
      return <FoundMatches />;
    default:
      throw new Error('Unknown step');
  }
};
// const getPageUrl = (activePage: number) => {
//   switch (activePage) {
//     case WELCOME_PAGE:
//       return `/matrimonials/welcomePage`;
//     case CREATE_MATRIMONY:
//       return `/matrimonials/createMatrimony`;
//     case STEP_FORM:
//       return `/matrimonials/stepForm`;
//     case PREVIEW:
//       return `/matrimonials/preview`;
//     case FOUND_MATCHES:
//       return `/matrimonials/foundMatches`;
//     default:
//       throw new Error('Unknown step');
//   }
// };

const theme = createTheme();

export default function App() {
  const { activePage } = useContext(AppContext);
  // const navigateToNextPage = () => {
  //   // const nextPage = activePage + 1; // Increment activePage by 1
  //   // handleChangePageVal(nextPage); // Update activePage in context
  //   const nextPageUrl = getPageUrl(activePage); // Get URL for next page
  //   navigate(nextPageUrl); // Navigate to next page
  // };

  // useEffect(() => {
  //   console.log('Active page ', activePage);
  //   navigateToNextPage();
  //   // handlePages(activePage);
  // }, [activePage]);
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          {handlePages(activePage)}
        </Container>
      </ThemeProvider>
    </div>
  );
}
