import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './styleUtils/welcomeStyles.css';
import { AppContext } from './Context';
import ExistingProfileCard from './componentUtils/existingProfileCard';
import { getLoggedInUserId } from '../../actions/authAction';
import { getAllMatrimonialProfiles, getMatrimonialProfiles } from '../../actions/matrimonialAction';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// // Define interface for profile properties
// interface Profile {
//   _id: string;
//   name: string;
//   age: string;
//   occupation: string;
//   // Add more properties as needed
// }

function MatrimonyPage() {
  const [loading, setLoading] = useState(false);
  const { handleNextPage, activePage } = useContext(AppContext);
  const [profiles, setProfiles] = useState([]); // Initialize as empty array
  const [allProfiles, setAllProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [showAllProfiles, setShowAllProfiles] = useState(false);
  const [filter, setFilter] = useState('All'); // Add filter state
  const loggedInUserId = getLoggedInUserId();
  //   const buttons = [
  //     {
  //       buttonName: '+ Add Profile',
  //       wrapperStyle: footerButtonStyleContained,
  //       varient: 'contained',
  //       buttonStyle: materialUIButtonStyle,
  //       disabled: false,
  //       onClick: handleNextPage,
  //     },
  //   ];
  const handleShowAllProfiles = async () => {
    try {
      const result = await getAllMatrimonialProfiles();
      setAllProfiles(result?.profiles);
      setShowAllProfiles(true);
    } catch (error) {
      console.error('Error fetching all profiles:', error);
    }
  };

  const filterProfiles = (profilesToFilter) => {
    if (filter === 'Male') {
      return profilesToFilter.filter((profile) => profile.gender === 'male');
    } else if (filter === 'Female') {
      return profilesToFilter.filter((profile) => profile.gender === 'female');
    } else if (filter === 'Verified') {
      return profilesToFilter.filter((profile) => profile.graph_node_id);
    } else if (filter === 'Unverified') {
      return profilesToFilter.filter((profile) => !profile.graph_node_id);
    } else {
      return profilesToFilter; // If 'All' is selected, return all profiles
    }
  };

  const getProfiles = async () => {
    try {
      setLoading(true);
      const response = await getMatrimonialProfiles({ node_id: loggedInUserId });
      setLoading(false);
      if (response?.status) {
        setProfiles(response?.profiles || []);
        setFilteredProfiles(filterProfiles(response?.profiles || []));
      } else {
        toast.error(`Error: Failed to fetch matrimonial profiles, ${response.error_message}`);
        console.log('Error: Failed to fetch matrimonial profiles');
      }
    } catch (error) {
      console.error('Error: ', error);
      toast.error(`Error: ${error}`);
    }
  };
  useEffect(() => {
    getProfiles();
  }, [loggedInUserId]);

  useEffect(() => {
    // Update the filtered profiles whenever the filter or profiles change
    setFilteredProfiles(filterProfiles(profiles));
  }, [filter, profiles]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value); // Update filter state
  };

  const handleOnClick = () => {
    handleNextPage();
    // const nextPage = activePage + 1;
    // navigate(`/matrimonials/createMatrimony`);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 16px' }}>
        <h4>Profiles created in your family ({filteredProfiles.length})</h4>
        {/* Filter Dropdown */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="filter-select-label">Filter</InputLabel>
            <Select
              labelId="filter-select-label"
              value={filter}
              onChange={handleFilterChange}
              label="Filter"
              sx={{ height: 36, paddingTop: 0, paddingBottom: 0 }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Verified">Verified</MenuItem>
              <MenuItem value="Unverified">Unverified</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          variant="contained"
          sx={{
            width: 'auto',
            borderRadius: '100px',
          }}
          onClick={handleOnClick}
        >
          + Add Profile
        </Button>
      </div>

      {loading ? (
        <LoadingSpinner message="Loading..." />
      ) : filteredProfiles.length ? (
        <>
          {filteredProfiles.map((profile, index) => (
            <ExistingProfileCard profile={profile} key={index} edit={true} postDeleteProfile={getProfiles} />
          ))}
        </>
      ) : (
        'No Matrimony Profiles Created'
      )}

      <div>
        <button className="matrimony-button" onClick={handleShowAllProfiles}>
          Get All Matrimony List
        </button>
        {showAllProfiles && (
          <>
            <h4>All profiles created</h4>
            {allProfiles.map((profile, index) => (
              <ExistingProfileCard profile={profile} key={index} edit={true} postDeleteProfile={getProfiles} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default MatrimonyPage;
